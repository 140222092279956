.conteinerCadastrosGuiasGratuitos {
    display: flex;
    flex-direction: column;
    background-color: #f9c711;

    img {
        height: 15rem;
        max-width: 100%;
        margin-top: 1rem;
    }
}

.containerDados {
    display: flex;
    justify-content: space-between;
}

.conteinerEsquerda {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    img {
        display: flex;
        justify-content: center;
        height: auto;
        margin-left: 1rem;
        width: 100%;
    }

    p {
        text-align: center;
        font-size: 1.5rem;
        font-weight: bold;
    }
}

.conteinerDireita {
    width: 100%;

    h1 {
        margin-top: 1rem;
        padding: 1rem;
        background-color: #f96211;
        border-radius: 1rem;
        width: 70%;
        text-align: center;
    }

    form {
        display: flex;
        flex-direction: column;
        align-items: center;

        input {
            text-align: center;
            font-weight: bold;
            height: 3rem;
            width: 80%;
            margin-top: 1rem;
            border-radius: 1rem;
        }

        select {
            text-align: center;
            font-weight: bold;
            height: 3rem;
            width: 80%;
            margin-top: 1rem;
            border-radius: 1rem;

            option {
                text-align: center;
                font-weight: bold;
                height: 3rem;
            }
        }

        button {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #0015f7;
            margin-top: 1rem;
            height: 3rem;
            border-radius: 1rem;
            width: 50%;
            cursor: pointer;

            font-size: 2rem;
            font-weight: bold;
            text-align: center;
            color: #FFFFFF;
        }

        p {
            font-weight: bold;
            margin-top: 0.5rem;
            font-size: 0.7rem;
        }
    }
}


@media only screen and (max-width: 700px) {
    .conteinerCadastrosGuiasGratuitos {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        img {
            height: 15rem;
            max-width: 100%;
            margin-top: 1rem;
        }
    }

    .containerDados {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin: 1rem;
        text-align: center;
    }

    .conteinerDireita {
        form {
            select {
                text-align: center;
                font-weight: bold;
                height: 3rem;
                width: 80%;
                margin-top: 1rem;
                border-radius: 1rem;
    
                option {
                    font-size: 0.7rem;
                    text-align: center;
                    font-weight: bold;
                    height: 3rem;
                }
            }
            button {
                font-size: 1rem;
                cursor: pointer;
            }
        }
    }

    .conteinerEsquerda{
        img {
           margin: 1rem;
        }
    }
}